<template>
  <base-card>
    <v-card-title>
      <v-btn
        color="primary"
        @click="isShowInviteDialog = true"
      >
        <v-icon>mdi-send</v-icon>
        {{ $t("partner.sendInvite") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="partnerList"
        :items-per-page="paginationLimit"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md mt-2"
      >
        <template v-slot:item.searchType="{item}">
          {{ formatServiceType(item.searchType) }}
        </template>

        <template v-slot:item.level="{item}">
          {{ formatPartnerLevel(item.level) }}
        </template>

        <template v-slot:item.isActive="{item}">
          <v-chip
            v-if="item.isActive"
            class="px-4"
            color="primary"
          >
            <v-icon left>
              mdi-checkbox-marked-circle
            </v-icon>
            {{ $t('order.approved') }}
          </v-chip>
          <v-chip
            v-else
            class="px-4"
            color="orange darken-4 white--text"
          >
            <v-icon left>
              mdi-account-clock
            </v-icon>
            {{ $t('order.pending') }}
          </v-chip>
        </template>

        <template v-slot:item.action="{item}">
          <div class="d-flex">
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ma-1"
                  fab
                  x-small
                  color="primary"
                  @click.native="showChangeSearchTypeDialog(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t("common.edit") }} {{ $t("partner.searchType") }}
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ma-1"
                  fab
                  x-small
                  :color="item.isActive ? 'error' : 'success'"
                  @click.native="updatePartnerStatus(item)"
                >
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  item.isActive
                    ? $t("common.close")
                    : $t("common.approve")
                }}
              </span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>

    <SendInviteDialog
      v-model="isShowInviteDialog"
      @cancel="isShowInviteDialog = false"
    />

    <UpdatePartnerInfoDialog
      v-model="isShowUpdatePartnerInfoDialog"
      :partner="selectedPartner"
      @update-partner="updatePartnerInfo"
    />
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'
  import _ from 'lodash'

  import util from '@/utils'
  import {
    showSuccessDialog,
    showErrorDialog,
    showQuestionDialog,
  } from '@/utils/swalDialog'

  import SendInviteDialog from './SendInviteDialog'
  import UpdatePartnerInfoDialog from './UpdatePartnerInfoDialog'

  export default {
    metaInfo: {
      title: 'Partners',
    },

    components: {
      SendInviteDialog,
      UpdatePartnerInfoDialog,
    },

    data () {
      return {
        paginationLimit: 10,
        currentPage: 1,
        totalPages: 0,
        isShowInviteDialog: false,
        isShowUpdatePartnerInfoDialog: false,
        selectedPartner: {},
        selectedIndex: -1,
        partnerList: [],
        headers: [
          { value: 'index', text: this.$t('common.number'), width: 70, sortable: false },
          { value: 'companyName', text: this.$t('partner.companyName'), width: 180, sortable: false },
          { value: 'code', text: this.$t('partner.partnerCode'), width: 120, sortable: false },
          { value: 'taxId', text: this.$t('partner.taxId'), width: 200, sortable: false },
          { value: 'level', text: this.$t('partner.level'), width: 120, sortable: false },
          { value: 'phoneNumber', text: this.$t('common.phoneNumber'), width: 180 },
          { value: 'adminName', text: this.$t('partner.managerName'), sortable: false },
          { value: 'adminEmail', text: this.$t('partner.managerEmail'), sortable: false },
          { value: 'searchType', text: this.$t('partner.searchType'), width: 180, sortable: false },
          { value: 'isActive', text: this.$t('common.status'), width: 120, sortable: false },
          { value: 'action', text: this.$t('common.action'), width: 120, sortable: false },
        ],
      }
    },

    watch: {
      currentPage(val) {
        this.currentPage = val
        this.loadPartners()
      }
    },

    mounted () {
      this.loadPartners()
    },

    methods: {
      ...mapActions({
        fetchPartners: 'fetchPartners',
        updatePartner: 'updatePartner',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async loadPartners() {
        const pagination = {
          offset: (this.currentPage - 1) * this.paginationLimit,
          limit: this.paginationLimit,
        }

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)
        try {
          const response = await this.fetchPartners(pagination)

          this.totalPages = Math.ceil(response.count / this.paginationLimit)
          this.partnerList = response.rows.map((partner, index) => {
            return {
              ...partner,
              index: (this.currentPage - 1) * this.paginationLimit + index + 1,
              status: partner.isActive
                ? this.$t('order.approved')
                : this.$t('order.pending'),
            }
          })
        } catch (error) {
          // No need to do anything
        }
        this.setLoading(false)
      },

      async updatePartnerStatus(row) {
        const updateData = {
          ...row,
          isActive: !row.isActive,
        }

        const questionTitle = row.isActive
          ? this.$t('partner.closePartner')
          : this.$t('partner.activatePartner')
        const questionText = this.$t(
          'partner.areYouSureYouWantCloseOrActivatePartner',
          {
            action: row.isActive
              ? this.$t('common.close').toLowerCase()
              : this.$t('common.activate').toLowerCase(),
          }
        )
        const confirmBtnText = row.isActive
          ? `${this.$t('common.yes')}, ${this.$t('common.close')}`
          : `${this.$t('common.yes')}, ${this.$t('common.activate')}`

        const btnResponse = await showQuestionDialog(
          questionTitle,
          questionText,
          confirmBtnText
        )

        if (!btnResponse.value) {
          return
        }

        const spinnerText = row.isActive
          ? this.$t('partner.closingPartner')
          : this.$t('partner.activatingPartner')
        this.setLoadingText(spinnerText)
        this.setLoading(true)
        try {
          const partner = await this.updatePartner(updateData)
          const updatedPartner = {
            ...partner,
            index: row.index,
            adminEmail: row.adminEmail,
            adminName: row.adminName,
          }

          const index = (row.index - 1) % this.paginationLimit
          this.partnerList.splice(index, 1, updatedPartner)

          showSuccessDialog(
            partner.isActive
              ? this.$t('partner.activatePartnerSuccessfully')
              : this.$t('partner.closePartnerSuccessfully')
          )
        } catch (error) {
          const errorMessage = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorMessage)
        }
        this.setLoading(false)
      },

      showChangeSearchTypeDialog(row) {
        this.selectedPartner = _.cloneDeep(row)
        this.selectedIndex = (row.index - 1) % this.paginationLimit
        this.isShowUpdatePartnerInfoDialog = true
      },

      async updatePartnerInfo({ searchType, level } = data) {
        this.isShowUpdatePartnerInfoDialog = false
        this.selectedPartner.searchType = searchType
        this.selectedPartner.level = level

        this.setLoadingText(this.$t('partner.updatingPartnerInfo'))
        this.setLoading(true)
        try {
          await this.updatePartner(this.selectedPartner)

          this.partnerList[this.selectedIndex].searchType =
            this.selectedPartner.searchType
          this.partnerList[this.selectedIndex].level =
            this.selectedPartner.level

          showSuccessDialog(this.$t('partner.updateInformationSuccessfully'))
        } catch (error) {
          const errorMessage = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorMessage)
        }
        this.setLoading(false)
      },

      formatServiceType(value) {
        if (value === 'both') {
          return `${this.$t('price.commercial')}, ${this.$t('price.residential')}`.toUpperCase()
        }

        return this.$t(`price.${value}`).toUpperCase()
      },

      formatPartnerLevel(level) {
        return _.startCase(level)
      },
    }
  }
</script>
