<template>
  <v-dialog
    scrollable
    persistent
    :width="500"
    v-model="dialog">
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title
          class="white--text"
          v-text="`${$t('common.update')} ${$t('partner.companyInfo')}`"
        />
        <v-spacer />
        <v-btn color="white" icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pa-6"
        style="max-height:65vh; height:auto; overflow:auto;"
      >
        <v-select
          v-model="searchType"
          :items="searchTypeList"
          :label="$t('partner.searchType')"
          hide-details
          item-text="label"
          item-value="value"
        />

        <v-select
          v-model="level"
          :items="levelList"
          :label="$t('partner.level')"
          hide-details
          item-text="label"
          item-value="value"
          class="mt-5"
        />
      </v-card-text>

      <v-card-actions class="justify-center mb-2">
        <v-btn
          class="primary px-4 mr-4"
          @click.native="updatePartnerInfo"
        >
          {{ $t("common.update") }} {{ $t("partner.companyInfo") }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    partner: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      searchTypeList: [
        {
          label: this.$t('price.commercial'),
          value: 'commercial',
        },
        {
          label: this.$t('price.residential'),
          value: 'residential',
        },
        {
          label: this.$t('price.bothOfThem'),
          value: 'both',
        },
      ],
      searchType: '',
      levelList: [
        {
          label: this.$t('partner.bronze'),
          value: 'bronze',
        },
        {
          label: this.$t('partner.silver'),
          value: 'silver',
        },
        {
          label: this.$t('partner.gold'),
          value: 'gold',
        },
      ],
      level: '',
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  watch: {
    dialog(value) {
      if (!value) {
        return
      }

      this.searchType = this.partner?.searchType
      this.level = this.partner?.level
    },
  },

  methods: {
    updatePartnerInfo() {
      this.$emit('update-partner', {
        searchType: this.searchType,
        level: this.level,
      })
    },
  },
}
</script>
