<template>
  <v-dialog
    scrollable
    persistent
    :width="600"
    v-model="dialog">
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title
          class="white--text"
          v-text="`${$t('partner.sendInvite')} ${$t('common.to')} ${$t('partner.partner')}`"
        />
        <v-spacer />
        <v-btn color="white" icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pa-6"
        style="max-height:65vh; height:auto; overflow:auto;"
      >
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="companyName"
                :label="`${$t('partner.companyName')}*`"
                :rules="companyNameRules"
              />
            </v-col>

            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="email"
                :label="`${$t('common.email')}*`"
                type="email"
                :rules="emailRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center mb-2">
        <v-btn
          class="primary px-4 mr-4"
          :disabled="!validForm"
          @click.native="sendInvite"
        >
          {{ $t("partner.sendInvite") }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import util from '@/utils'
import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      companyName: '',
      companyNameRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      email: '',
      emailRules: [
        (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.email') }),
        (v) => /.+@.+\..+/.test(v) || this.$t('error.invalidEmail'),
      ],
      validForm: true,
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  watch: {
    dialog(value) {
      this.companyName = ''
      this.email = ''
      this.validForm = true
    },
  },

  methods: {
    ...mapActions({
      invitiePartner: 'invitiePartner',
      setLoading: 'setLoading',
      setLoadingText: 'setLoadingText',
    }),

    async sendInvite() {
      const isValid = this.$refs.form.validate()

      if (!isValid) {
        return
      }

      this.setLoadingText(this.$t('partner.sendingInviteEmailToPartner'))
      this.setLoading(true)
      try {
        await this.invitiePartner({
          email: this.email,
          companyName: this.companyName,
        })

        this.setLoading(false)
        await showSuccessDialog(this.$t('partner.sentInvitationEmailSuccessfully'))

        this.dialog = false
      } catch (error) {
        const errorMessage = util.getErrorResponse(error)
        showErrorDialog(this.$t('common.error'), errorMessage)
        this.setLoading(false)
      }
    },
  },
}
</script>
